import { Config } from '@bugsnag/core';
import { environment } from '@environment';

export const bugsnagConfig: Config = {
  releaseStage: environment.platform,
  appVersion: environment.version,
  apiKey: environment.bugsnag.key,
  autoTrackSessions: false,
  onError: (event) => {
    if (event.originalError.name === 'HttpErrorResponse') {
      if (event.originalError.status === 403 || event.originalError.status === 404) {
        return false;
      }

      // Errors due to cors, adblock or network failures
      if (event.originalError.message.toLowerCase().includes('0 Unknown Error'.toLowerCase())) {
        return false;
      }
    }

    if (event.originalError.name === 'TypeError') {
      // Errors from widgets or to web-metrics-api failed fetch requests due to network failures
      if (event.originalError.message === 'NetworkError when attempting to fetch resource') {
        return false;
      }

      // Errors from failed fetch requests of consent and web metrics API
      const requestEventId = (event.breadcrumbs ?? []).findIndex((breadcrumb) => breadcrumb?.type === 'request');
      const requestEvent = (event.breadcrumbs ?? [])?.[requestEventId];
      if (
        requestEvent &&
        requestEvent.message === 'fetch() error' &&
        requestEvent.metadata?.['request']?.includes('_api/web-metrics-api')
      ) {
        return false;
      }
    }

    return true;
  },
};
