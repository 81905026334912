import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFrenchIban',
  standalone: true,
})
export class FrenchIbanFormatterPipe implements PipeTransform {
  transform(value?: string): string {
    const blocks = [
      value?.substr(0, 4),
      value?.substr(4, 4),
      value?.substr(8, 4),
      value?.substr(12, 4),
      value?.substr(16, 4),
      value?.substr(20, 4),
      value?.substr(24, 4),
      value?.substr(28, 3),
    ];
    return blocks.join(' ').trim();
  }
}
