import { createReducer, on } from '@ngrx/store';
import { loadServicesSuccess } from '@stores/services/services.action';
import { CountryService } from '@wizbii/utils/models';

export const serviceKey = 'service';

export type ServiceState = {
  service?: CountryService;
};

export const initialState: ServiceState = { service: undefined };

export const serviceReducer = createReducer(
  initialState,
  on(loadServicesSuccess, (_, { service }) => {
    return { service };
  })
);
