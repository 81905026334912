import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { ClientListOptions, HelpiiUser, KycStatusCount } from '@models/client.model';
import { DossierWithClient } from '@models/dossier.model';
import { HistoryEvent } from '@models/history-event.model';
import { Transaction } from '@models/transaction.model';
import { Client, LocaleEnum } from '@wizbii/utils/models';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelpiiWebservice {
  private readonly _http = inject(HttpClient);
  private readonly _apiUrl = environment.api.fibii;

  getClients(options?: ClientListOptions): Observable<Client[]> {
    return this._http.get<Client[]>(`${this._apiUrl}/clients`, { params: { ...(options as any) } });
  }

  getClientsFromIds(clientIds: string[]): Observable<Client[]> {
    return this._http.get<Client[]>(`${this._apiUrl}/clients`, {
      params: { clientIds: clientIds.join(',') },
    });
  }

  getClientByUserId(userId: string): Observable<Client> {
    return this._http.get<Client>(`${this._apiUrl}/v2/clients/${userId}`);
  }

  getAdvisors(): Observable<HelpiiUser[]> {
    return this._http.get<HelpiiUser[]>(`${this._apiUrl}/users`);
  }

  assignClients(
    fromOwnerId: string | null,
    toOwnerIds: string[],
    limit: number | null,
    onlyAccompaniment: boolean = false
  ): Observable<Client[]> {
    return this._http.post<Client[]>(`${this._apiUrl}/v2/clients/assign`, {
      fromOwnerId,
      toOwnerIds,
      limit,
      onlyAccompaniment,
    });
  }

  getClientCountByOwnerId(ownerId: string | undefined, onlyAccompaniment: boolean = false): Observable<number> {
    let params = new HttpParams();
    params = params.append('onlyAccompaniment', onlyAccompaniment);
    if (ownerId) {
      params = params.append('ownerId', ownerId);
    }

    return this._http.get<number>(`${this._apiUrl}/v2/clients/owner/count`, { params });
  }

  getHistoryEvents(userId: string): Observable<HistoryEvent[]> {
    return this._http.get<HistoryEvent[]>(`${this._apiUrl}/history-events`, {
      params: { userId, row: '1000' },
    });
  }

  createHistoryEvent(historyEvent: Partial<HistoryEvent>): Observable<HistoryEvent> {
    return this._http.post<HistoryEvent>(`${this._apiUrl}/history-events`, historyEvent);
  }

  updateHistoryEvent(historyEvent: Partial<HistoryEvent>): Observable<HistoryEvent> {
    return this._http.put<HistoryEvent>(`${this._apiUrl}/history-events`, historyEvent);
  }

  deleteHistoryEvent(historyEventId: string): Observable<HistoryEvent> {
    return this._http.delete<HistoryEvent>(`${this._apiUrl}/history-events/${historyEventId}`);
  }

  trackPrivateUserFileAccess(documentId: string): Observable<any> {
    return this._http.post(`${this._apiUrl}/access-log/access_private_user_file`, {
      metaData: {
        documentId,
      },
    });
  }

  getDossiersFromIds(dossierIds: string[]): Observable<DossierWithClient[]> {
    if (dossierIds.length === 0) return of([]);
    const fetchDossierChunk = (ids: string[]) =>
      this._http.get<DossierWithClient[]>(`${this._apiUrl}/v2/dossiers/ids`, {
        params: { dossierIds: ids.join(',') },
      });
    const chunkedDossiers = dossierIds.reduce((resultArray: string[][], item, index) => {
      const chunkSize = 100;
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex] = [...resultArray[chunkIndex], item];

      return resultArray;
    }, []);
    return forkJoin(chunkedDossiers.map((ids) => fetchDossierChunk(ids).pipe(catchError(() => [])))).pipe(
      map((chunkedDossiers) => chunkedDossiers.reduce((acc, chunk) => [...acc, ...chunk], []))
    );
  }

  getKycStatusCount({ status, ownerId }: ClientListOptions): Observable<KycStatusCount> {
    return this._http.get<KycStatusCount>(`${this._apiUrl}/clients/kycStatus/count`, {
      params: {
        ...(!!status && { status }),
        ...(!!ownerId && { ownerId }),
      },
    });
  }

  createKYC(userId: string): Observable<void> {
    return this._http.post<void>(`${this._apiUrl}/v2/clients/${userId}/kyc`, {});
  }

  getTransactionsByClient(userId: string): Observable<Transaction[]> {
    return this._http.get<Transaction[]>(`${this._apiUrl}/transactions/client/${userId}`, {});
  }

  getDownloadTransactionPdf(userId: string, month: number, year: number): Observable<Blob> {
    const urlPdf = `${environment.api.fibii}/clients/receipt/user/${userId}/month/${month}/year/${year}/pdf`;
    return this._http.get(urlPdf, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob',
    });
  }

  validateDropitoData(suggestionId: string, userId: string, locale = LocaleEnum.fr_FR): Observable<any> {
    return this._http.get(`${environment.api.fibii}/dropito/used-data/${locale}/${suggestionId}/${userId}`);
  }

  createDropitoTask(userId: string, helpId: string, locale = LocaleEnum.fr_FR): Observable<any> {
    return this._http.post(`${environment.api.fibii}/dropito/task/${locale}`, {
      userId,
      helpId,
    });
  }

  generatedAndDownloadRIB(userId: string): Observable<Blob> {
    return this._http.get(`${environment.api.bankingProcessor}/v1/user/${userId}/bankAccount/rib`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob',
    });
  }
}
