/* eslint-disable no-console */
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { bugsnagConfig } from '@config/bugsnag.config';
import { CoreComponent } from '@core/components/core/core.component';
import { FrenchIbanFormatterPipe } from '@core/pipes/french-iban-formatter.pipe';
import { AlgoliaCredentialFetcherService } from '@core/services/algolia-credential-fetcher.service';
import { HelpiiWebservice } from '@core/services/helpii.webservice';
import { JwtService } from '@core/services/jwt.service';
import { environment } from '@environment';
import { RoleTypes } from '@models/roles';
import { provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { Store, provideState, provideStore } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as serviceEffects from '@stores/services/services.effect';
import { serviceKey, serviceReducer } from '@stores/services/services.reducer';
import {
  FILE_PICKER_SERVICE_TOKEN,
  FileWebService,
  MUST_BE_AUTHORIZED_GUARD_CONFIG,
} from '@wizbii-components/bo-angular-ui';
import { JWT_SERVICE_TOKEN, JwtInterceptor, JwtInterceptorFn } from '@wizbii-utils/angular/jwt';
import {
  NGRX_JWT_STATE_CONFIG,
  jwtDeleteEffect,
  jwtDeleteHardEffect,
  jwtFeatureKey,
  jwtReducer,
  jwtSetEffect,
  loadJwt,
  roles,
} from '@wizbii-utils/angular/stores';
import {
  ACCOUNT_API_CONFIG,
  AUTHENTICATION_API_CONFIG,
  AccountWebservice,
  AuthenticationWebservice,
  BANKING_PROCESSOR_API_CONFIG,
  BankingFlowConfigurationWebservice,
  BankingPartnerWebservice,
  BankingSwanMigrationWebservice,
  BankingUserWebservice,
  CityWebservice,
  FIBII_API_CONFIG,
  FILE_API_CONFIG,
  FibiiWebservice,
  FileWebservice as FileApiWebservice,
  PLACE_API_CONFIG,
  SUGGESTIONS_API_CONFIG,
  SuggestionsWebservice,
} from '@wizbii-utils/angular/webservices';
import { ALGOLIA_CREDENTIALS_FETCHERS_TOKEN, AlgoliaClientProvider, type CredentialsFetcher } from '@wizbii/algolia';
import { LocaleEnum } from '@wizbii/utils/models';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

Bugsnag.start(bugsnagConfig);
BugsnagPerformance.start({ apiKey: environment.bugsnag.key });

bootstrapApplication(CoreComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    provideAnimations(),
    importProvidersFrom(QuicklinkModule),
    provideHttpClient(withFetch(), withInterceptors([JwtInterceptorFn])),
    provideRouter(
      [
        {
          path: '',
          loadChildren: () => import('./app/features/routes').then((module) => module.FEATURES_ROUTES),
        },
      ],
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }),
      withPreloading(QuicklinkStrategy),
      withComponentInputBinding(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
      })
    ),
    provideStore({ router: routerReducer }),
    provideState({ name: jwtFeatureKey, reducer: jwtReducer }),
    provideState({ name: serviceKey, reducer: serviceReducer }),
    provideEffects({ loadJwt, jwtDeleteEffect, jwtSetEffect, jwtDeleteHardEffect, ...serviceEffects }),
    provideRouterStore({
      routerState: RouterState.Full,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    importProvidersFrom(
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: false,
        autoPause: true,
        trace: false,
        traceLimit: 75,
        name: 'Helpii',
      })
    ),
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    {
      provide: NGRX_JWT_STATE_CONFIG,
      useValue: {
        jwtCookieName: 'wizbii_bo',
        expiryCookieName: 'wizbii_bo_expiry',
        appEnvFqdn: environment.domain.cookieDomain,
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    AuthenticationWebservice,
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
        locale: LocaleEnum.fr_FR,
        cookieDomains: environment.domain.cookieDomain,
      },
    },
    {
      provide: SUGGESTIONS_API_CONFIG,
      useValue: {
        baseUrl: `${environment.api.suggestion}`,
      },
    },
    SuggestionsWebservice,
    {
      provide: FIBII_API_CONFIG,
      useValue: {
        baseUrl: environment.api.fibii,
      },
    },
    {
      provide: MUST_BE_AUTHORIZED_GUARD_CONFIG,
      useFactory: (store: Store) => ({
        listRoles$: store.select(roles),
        listAuthorizedRoles: [
          RoleTypes.ROLE_HELPII,
          RoleTypes.ROLE_HELPII_MANAGER,
          RoleTypes.ROLE_BANKING_FLOW_CONFIGURATOR,
        ],
        platform: environment.platform,
      }),
      deps: [Store],
    },
    FibiiWebservice,
    { provide: LOCALE_ID, useValue: 'fr_FR' },
    FrenchIbanFormatterPipe,
    {
      provide: BANKING_PROCESSOR_API_CONFIG,
      useValue: {
        baseUrl: environment.api.bankingProcessor,
      },
    },
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.urls.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },
    {
      provide: FILE_PICKER_SERVICE_TOKEN,
      useClass: FileWebService,
    },
    AlgoliaCredentialFetcherService,
    {
      provide: ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
      useFactory: (customerCredentialsFetcherService: CredentialsFetcher) => ({
        main: customerCredentialsFetcherService,
      }),
      deps: [AlgoliaCredentialFetcherService],
    },
    AlgoliaClientProvider,
    HelpiiWebservice,
    FileApiWebservice,
    BankingUserWebservice,
    BankingSwanMigrationWebservice,
    BankingPartnerWebservice,
    BankingFlowConfigurationWebservice,
    FrenchIbanFormatterPipe,
    CityWebservice,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
    AccountWebservice,
    {
      provide: ACCOUNT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.account,
        locale: environment.i18n.locale,
        baseUrlHost: environment.api.account,
      },
    },
    { provide: ErrorHandler, useFactory: () => new BugsnagErrorHandler() },
  ],
}).catch((err) => console.error(err));

registerLocaleData(localeFr, 'fr');
