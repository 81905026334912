import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Credentials, CredentialsFetcher } from '@wizbii/algolia';
import { Observable } from 'rxjs';

@Injectable()
export class AlgoliaCredentialFetcherService implements CredentialsFetcher {
  constructor(private readonly httpClient: HttpClient) {}

  fetchCredentials(): Observable<Credentials> {
    return this.httpClient.get<Credentials>(`${environment.api.fibii}/_credentials/algolia`);
  }
}
