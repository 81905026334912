import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { JwtServiceInterface, JwtTokens } from '@wizbii-utils/angular/jwt';
import { jwtDelete, jwtSet, selectJwt } from '@wizbii-utils/angular/stores';
import { AuthenticationWebservice } from '@wizbii-utils/angular/webservices';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JwtService implements JwtServiceInterface {
  readonly #store = inject(Store);
  readonly #authService = inject(AuthenticationWebservice);

  blacklistRoutes: (string | RegExp)[] = [
    'https://storage.googleapis.com',
    new RegExp(`https://auth.${environment.api.domain}(?!(/v1/user).*(/email|password|overview))`, 'i'),
  ];

  getTokens(): Observable<JwtTokens | undefined> {
    return this.#store.select(selectJwt).pipe(map((state) => state ?? undefined));
  }

  logout(): void {
    this.#store.dispatch(jwtDelete());
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.#authService.refreshTokenV2(tokens, environment.applicationId);
  }

  saveLocalTokens(jwtTokens: JwtTokens): void {
    this.#store.dispatch(jwtSet({ jwtTokens }));
  }
}
