import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { selectCurrentCountry } from '@stores/router/router.selectors';
import { loadServicesSuccess } from '@stores/services/services.action';
import { AccountWebservice } from '@wizbii-utils/angular/webservices';
import { LocaleEnum } from '@wizbii/utils/models';
import { map, switchMap } from 'rxjs';

export const initServices = createEffect(
  (actions$ = inject(Actions), accountWebservice = inject(AccountWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      switchMap(() => {
        const countryToLocale = {
          fr: LocaleEnum.fr_FR,
          it: LocaleEnum.it_IT,
        };

        const country = store.selectSignal(selectCurrentCountry)();

        return accountWebservice.getServicesByCountry(countryToLocale[country]);
      }),
      map((country) => country.services.find((service) => service.id === 'fibii')),
      map((service) => loadServicesSuccess({ service }))
    ),
  { functional: true }
);
