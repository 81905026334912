import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { LangEnum } from '@wizbii/utils/models';

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = getRouterSelectors();

export const selectCurrentCountry = createSelector(selectRouteParams, (params): LangEnum.fr | LangEnum.it => {
  if (!params?.country) {
    return LangEnum.fr;
  }

  if ([LangEnum.it, LangEnum.fr].includes(params?.country)) {
    return params?.country;
  }

  return LangEnum.fr;
});
